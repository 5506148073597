//
// Variables
// --------------------------------------------------

//== Colors
//
//## Follow kata design system

$white: #ffffff !default;
$red: #e53935 !default;
$green: #00c853 !default;
$yellow: #ffc400 !default;

$soft-kata-blue: #d8ebff !default;
$light-kata-blue: #7fbcff !default;
$kata-blue: #006fe6 !default;

$light-cobalt: #6386c7 !default;
$semi-cobalt: #2662cf !default;
$cobalt: #2053af !default;
$dark-cobalt: #19428c !default;

$gray-10: #f6f7f8 !default;
$gray-20: #edf1f2 !default;
$gray-30: #e2e6e8 !default;
$gray-40: #c2c7c8 !default;
$gray-50: #949a9d !default;
$gray-60: #676b6d !default;
$gray-70: #484c4f !default;
$gray-80: #24282d !default;

$red-light: #fa000a;

//== Spacing
//
$space-1: 0.615384615rem;
$space-2: 1.230769231rem;
$space-3: 1.846153846rem;
$space-4: 2.461538462rem;
$space-5: 3.076923077rem;
$space-6: 3.692307692rem;
$space-7: 4.307692308rem;
$space-8: 4.923076923rem;
$space-9: 5.538461538rem;
$space-10: 6.153846154rem;

$space-list: $space-1 $space-2 $space-3 $space-4 $space-5 $space-6 $space-7 $space-8 $space-9 $space-10;

//== Border Radius
$border-radius-xs: 2px;
$border-radius-small: 4px;
$border-radius-medium: 6px;
$border-radius-large: 8px;

//== Typography
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$root-font-size: 13px;
$root-line-height: 20px;

$h1-font-size: 1.85rem;
$h1-font-weight: 500;
$h1-line-height: 2.461rem;

$h2-font-size: 1.5rem;
$h2-font-weight: 500;
$h2-line-height: 2.153rem;

$title-font-size: 1.25rem;
$title-font-weight: 500;
$title-line-height: 1.846rem;

$subtitle-font-size: 1.075rem;
$subtitle-font-weight: 700;
$subtitle-line-height: 1.538rem;

$label-font-size: 0.925rem;
$label-font-weight: 700;
$label-line-height: 1.384rem;
$label-letter-spacing: 0.2px;

$small-font-size: 0.85rem;
$small-font-weight: 700;
$small-line-height: 1.23rem;

$body-font-size: 1rem;
$body-font-weight: normal;
$body-line-height: 2;

$tooltip-font-size: 1rem;
$tooltip-font-weight: normal;
$tooltip-letter-spacing: 0.2px;

//== Layer
$layer-100-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
$layer-200-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
$layer-300-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.15);
$layer-400-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.15);

$transition-fast: 0.3s;
$transition-normal: 0.5s;
$transition-slow: 0.75s;
$transition-easing: ease;

$break-medium: 1280px;
$break-large: 1366px;
$break-xlarge: 1440px;
