@import '~assets/sass/variables';

.CalendarDay {
  border: 1px solid $gray-30;
  padding: 0;
  box-sizing: border-box;
  color: $gray-70;
  cursor: pointer;
}

.CalendarDay__button {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  box-sizing: border-box;
}

.CalendarDay__button:active {
  outline: 0;
}

.CalendarDay--highlighted-calendar {
  background: #ffe8bc;
  color: #565a5c;
  cursor: default;
}

.CalendarDay--highlighted-calendar:active {
  background: $kata-blue;
}

.CalendarDay--outside {
  border: 0;
  cursor: default;
}

.CalendarDay--outside:active {
  background: #fff;
}

.CalendarDay--hovered {
  background: #e4e7e7;
  border: 1px double #d4d9d9;
  color: inherit;
}

.CalendarDay--blocked-minimum-nights {
  color: #cacccd;
  background: #fff;
  border: 1px solid #e4e7e7;
  cursor: default;
}

.CalendarDay--blocked-minimum-nights:active {
  background: #fff;
}

.CalendarDay--selected-span {
  background: #f6f7f8;
}

.CalendarDay--selected-span.CalendarDay--hovered,
.CalendarDay--selected-span:active {
  background: $light-kata-blue;
  border: 1px double $light-kata-blue;
  color: #fff;
}

.CalendarDay--selected-span.CalendarDay--last-in-range {
  border-right: $kata-blue;
}

.CalendarDay--hovered-span,
.CalendarDay--after-hovered-start {
  background: rgba(49, 139, 243, 0.15);
  border: 1px double rgba(49, 139, 243, 0.1);
  /*color: rgba(49, 139, 243, 0.9);*/
  color: #333;
}

.CalendarDay--hovered-span:active,
.CalendarDay--after-hovered-start:active {
  background: rgba(49, 139, 243, 0.55);
}

.CalendarDay--selected-start,
.CalendarDay--selected-end,
.CalendarDay--selected {
  background: $kata-blue;
  border: 1px double $kata-blue;
  color: #fff;
}

.CalendarDay--selected-start:active,
.CalendarDay--selected-end:active,
.CalendarDay--selected:active {
  background: $kata-blue;
}

.CalendarDay--blocked-calendar {
  background: #cacccd;
  color: #82888a;
  cursor: default;
}

.CalendarDay--blocked-calendar:active {
  background: #cacccd;
}

.CalendarDay--blocked-out-of-range {
  color: #cacccd;
  background: #fff;
  border: 1px solid #e4e7e7;
  cursor: default;
}

.CalendarDay--blocked-out-of-range:active {
  background: #fff;
}

.CalendarMonth {
  text-align: center;
  padding: 0 13px;
  vertical-align: top;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.CalendarMonth table {
  border-collapse: collapse;
  border-spacing: 0;
  caption-caption-side: initial;
}

.CalendarMonth--horizontal:first-of-type,
.CalendarMonth--vertical:first-of-type {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.CalendarMonth--horizontal {
  display: inline-block;
  min-height: 100%;
}

.CalendarMonth--vertical {
  display: block;
}

.CalendarMonth__caption {
  color: #3c3f40;
  margin-top: 7px;
  font-size: 14px;
  text-align: center;
  margin-bottom: 2px;
  caption-side: initial;
}

.CalendarMonth--horizontal .CalendarMonth__caption,
.CalendarMonth--vertical .CalendarMonth__caption {
  padding: 15px 0 35px;
}

.CalendarMonth--vertical-scrollable .CalendarMonth__caption {
  padding: 5px 0;
}

.CalendarMonthGrid {
  background: #fff;
  z-index: 0;
  text-align: left;
}

.CalendarMonthGrid--animating {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -moz-transition: -moz-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  z-index: 1;
}

.CalendarMonthGrid--horizontal {
  position: absolute;
  left: 9px;
}

.CalendarMonthGrid--vertical {
  margin: 0 auto;
}

.CalendarMonthGrid--vertical-scrollable {
  margin: 0 auto;
  overflow-y: scroll;
}

.DayPicker {
  background: #fff;
  position: relative;
  text-align: left;
}

.DayPicker--horizontal {
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
}

.DayPicker--horizontal.DayPicker--portal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}

.DayPicker--vertical.DayPicker--portal {
  position: initial;
}

.DayPicker__focus-region {
  outline: none;
}

.DayPicker__week-headers {
  position: relative;
}

.DayPicker--horizontal .DayPicker__week-headers {
  margin-left: 9px;
}

.DayPicker__week-header {
  color: var(--gray-60);
  position: absolute;
  top: 62px;
  z-index: 2;
  padding: 0 13px;
  text-align: left;
}

.DayPicker__week-header ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
}

.DayPicker__week-header li {
  display: inline-block;
  text-align: center;
}

.DayPicker--vertical .DayPicker__week-header {
  left: 50%;
}

.DayPicker--vertical-scrollable {
  height: 100%;
}

.DayPicker--vertical-scrollable .DayPicker__week-header {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: white;
}

.DayPicker--vertical-scrollable .transition-container--vertical {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}

.DayPicker--vertical-scrollable .DayPicker__week-header {
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.transition-container {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.transition-container--horizontal {
  transition: height 0.2s ease-in-out;
}

.transition-container--vertical {
  width: 100%;
}

.DayPickerNavigation__prev,
.DayPickerNavigation__next {
  cursor: pointer;
  line-height: 0.78;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
}

.DayPickerNavigation__prev--default,
.DayPickerNavigation__next--default {
  border: none;
  color: $gray-60;
  background: $white;
}

.DayPickerNavigation__prev--default:focus,
.DayPickerNavigation__prev--default:hover,
.DayPickerNavigation__next--default:focus,
.DayPickerNavigation__next--default:hover {
  border: none;
}

.DayPickerNavigation__prev--default:active,
.DayPickerNavigation__next--default:active {
  background: $white;
}

.DayPickerNavigation--horizontal {
  position: relative;
}

.DayPickerNavigation--horizontal .DayPickerNavigation__prev,
.DayPickerNavigation--horizontal .DayPickerNavigation__next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  vertical-align: middle;
  top: 14px;
  height: 40px;
  width: 40px;
  padding: 6px 9px;
  z-index: 2;
  font-weight: 500;
  border: none;
  border-radius: 50%;
  line-height: 1;
  box-shadow: $layer-200-shadow;
  font-size: 16px;

  i::before {
    vertical-align: middle;
    font-size: 10px;
  }
}

.DayPickerNavigation--horizontal .DayPickerNavigation__prev {
  left: 22px;
}

.DayPickerNavigation--horizontal .DayPickerNavigation__prev--rtl {
  left: auto;
  right: 22px;
}

.DayPickerNavigation--horizontal .DayPickerNavigation__next {
  right: 22px;
}

.DayPickerNavigation--horizontal .DayPickerNavigation__next--rtl {
  right: auto;
  left: 22px;
}

.DayPickerNavigation--horizontal .DayPickerNavigation__prev--default svg,
.DayPickerNavigation--horizontal .DayPickerNavigation__next--default svg {
  height: 19px;
  width: 19px;
  fill: #82888a;
}

.DayPickerNavigation--vertical {
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 52px;
  width: 100%;
  z-index: 2;
}

.DayPickerNavigation--vertical .DayPickerNavigation__prev,
.DayPickerNavigation--vertical .DayPickerNavigation__next {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 50%;
}

.DayPickerNavigation--vertical .DayPickerNavigation__next--default {
  border-left: 0;
}

.DayPickerNavigation--vertical .DayPickerNavigation__prev--default,
.DayPickerNavigation--vertical .DayPickerNavigation__next--default {
  text-align: center;
  font-size: 2.5em;
  padding: 5px;
}

.DayPickerNavigation--vertical .DayPickerNavigation__prev--default svg,
.DayPickerNavigation--vertical .DayPickerNavigation__next--default svg {
  height: 42px;
  width: 42px;
  fill: #484848;
}

.DayPickerNavigation--vertical-scrollable {
  position: relative;
}

.DayPickerNavigation--vertical-scrollable .DayPickerNavigation__next {
  width: 100%;
}

.DayPickerKeyboardShortcuts__show,
.DayPickerKeyboardShortcuts__close {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
}

.DayPickerKeyboardShortcuts__show:active,
.DayPickerKeyboardShortcuts__close:active {
  outline: none;
}

.DayPickerKeyboardShortcuts__show {
  width: 22px;
  position: absolute;
  z-index: 2;
}

.DayPickerKeyboardShortcuts__show--bottom-right {
  border-top: 26px solid transparent;
  border-right: 33px solid $kata-blue;
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts__show--bottom-right:hover {
  border-right: 33px solid #008489;
}

.DayPickerKeyboardShortcuts__show--bottom-right .DayPickerKeyboardShortcuts__show_span {
  bottom: 0;
  right: -28px;
}

.DayPickerKeyboardShortcuts__show--top-right {
  border-bottom: 26px solid transparent;
  border-right: 33px solid $kata-blue;
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts__show--top-right:hover {
  border-right: 33px solid #008489;
}

.DayPickerKeyboardShortcuts__show--top-right .DayPickerKeyboardShortcuts__show_span {
  top: 1px;
  right: -28px;
}

.DayPickerKeyboardShortcuts__show--top-left {
  border-bottom: 26px solid transparent;
  border-left: 33px solid $kata-blue;
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts__show--top-left:hover {
  border-left: 33px solid #008489;
}

.DayPickerKeyboardShortcuts__show--top-left .DayPickerKeyboardShortcuts__show_span {
  top: 1px;
  left: -28px;
}

.DayPickerKeyboardShortcuts__show_span {
  color: #fff;
  position: absolute;
}

.DayPickerKeyboardShortcuts__panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
}

.DayPickerKeyboardShortcuts__title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.DayPickerKeyboardShortcuts__list {
  list-style: none;
  padding: 0;
}

.DayPickerKeyboardShortcuts__close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2;
}

.DayPickerKeyboardShortcuts__close svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.DayPickerKeyboardShortcuts__close svg:hover,
.DayPickerKeyboardShortcuts__close svg:focus {
  fill: #82888a;
}

.DayPickerKeyboardShortcuts__close:active {
  outline: none;
}

.KeyboardShortcutRow {
  margin: 6px 0;
}

.KeyboardShortcutRow__key-container {
  display: inline-block;
  white-space: nowrap;
  text-align: right;
  margin-right: 6px;
}

.KeyboardShortcutRow__key {
  font-family: monospace;
  font-size: 12px;
  text-transform: uppercase;
  background: #f2f2f2;
  padding: 2px 6px;
}

.KeyboardShortcutRow__action {
  display: inline;
  word-break: break-word;
  margin-left: 8px;
}

.DayPickerKeyboardShortcuts__panel--block .KeyboardShortcutRow {
  margin-bottom: 16px;
}

.DayPickerKeyboardShortcuts__panel--block .KeyboardShortcutRow__key-container {
  width: auto;
  text-align: left;
  display: inline;
}

.DayPickerKeyboardShortcuts__panel--block .KeyboardShortcutRow__action {
  display: inline;
}

.DateInput {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #757575;
  margin: 0;
  padding: 3px 8px;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 105px;
  vertical-align: middle;
}

.DateInput--with-caret::before,
.DateInput--with-caret::after {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: auto;
  border: 10px solid transparent;
  border-top: 0;
  left: 22px;
  z-index: 2;
}

.DateInput--with-caret::before {
  top: 62px;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.DateInput--with-caret::after {
  top: 63px;
  border-bottom-color: #fff;
}

.DateInput--disabled {
  background: #cacccd;
}

.DateInput__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.DateInput__input[readonly] {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DateInput__display-text {
  padding: 4px 8px;
  border-radius: 6px;
  white-space: nowrap;
  overflow: hidden;
}

.DateInput__display-text--has-input {
  color: #484848;
}

.DateInput__display-text--focused {
  background: #484c4f;
  border-color: #484c4f;
  color: #fff;
}

.DateInput__display-text--disabled {
  font-style: italic;
}

.screen-reader-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.DateRangePicker {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.DateRangePicker__picker {
  z-index: 9999;
  background-color: #fff;
  position: absolute;
  top: 72px;
}

.DateRangePicker__picker--rtl {
  direction: rtl;
}

.DateRangePicker__picker--direction-left {
  left: 0;
}

.DateRangePicker__picker--direction-right {
  right: 0;
}

.DateRangePicker__picker--portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.DateRangePicker__picker--full-screen-portal {
  background-color: #fff;
}

.DateRangePicker__close {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}

.DateRangePicker__close svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.DateRangePicker__close:hover,
.DateRangePicker__close:focus {
  color: #b0b3b4;
  text-decoration: none;
}

.DateRangePickerInput {
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 40px;
  background-color: #fff;
  border: 1px solid #e2e6e8;
  border-radius: 6px;
  vertical-align: top;
  overflow: hidden;
}

.DateRangePickerInput--disabled {
  background: #cacccd;
}

.DateRangePickerInput--rtl {
  direction: rtl;
}

.DateRangePickerInput__arrow {
  display: inline-block;
  vertical-align: middle;
}

/**
 * 1. Fit .icon-arrow-right within the arrow container
 */

.DateRangePickerInput__arrow .icon-arrow-right {
  vertical-align: middle;
  color: #484848;
  height: 6px; /* 1 */
  width: 10px; /* 1 */
}

.DateRangePickerInput__arrow .icon-arrow-right:before {
  font-size: 10px; /* 1 */
}

.DateRangePickerInput__arrow svg {
  vertical-align: middle;
  fill: #484848;
  height: 16px;
  width: 16px;
}

.DateRangePickerInput__clear-dates {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 10px 0 5px;
}

.DateRangePickerInput__clear-dates svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}

.DateRangePickerInput__clear-dates--hide {
  visibility: hidden;
}

.DateRangePickerInput__clear-dates:focus,
.DateRangePickerInput__clear-dates--hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.DateRangePickerInput__calendar-icon {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 0 0 5px;
}

.DateRangePickerInput__calendar-icon:focus {
  outline: none;
}

.DateRangePickerInput__calendar-icon svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}

.SingleDatePicker {
  position: relative;
  display: inline-block;
}

.SingleDatePicker__picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
  top: 72px;
}

.SingleDatePicker__picker--rtl {
  direction: rtl;
}

.SingleDatePicker__picker--direction-left {
  left: 0;
}

.SingleDatePicker__picker--direction-right {
  right: 0;
}

.SingleDatePicker__picker--portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.SingleDatePicker__picker--full-screen-portal {
  background-color: #fff;
}

.SingleDatePicker__close {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}

.SingleDatePicker__close svg {
  height: 15px;
  width: 15px;
  fill: #cacccd;
}

.SingleDatePicker__close:hover,
.SingleDatePicker__close:focus {
  color: #b0b3b4;
  text-decoration: none;
}

.SingleDatePickerInput {
  background-color: #fff;
  border: 1px solid #dbdbdb;
}

.SingleDatePickerInput--rtl {
  direction: rtl;
}

.SingleDatePickerInput__clear-date {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 10px 0 5px;
}

.SingleDatePickerInput__clear-date svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}

.SingleDatePickerInput__clear-date--hide {
  visibility: hidden;
}

.SingleDatePickerInput__clear-date:focus,
.SingleDatePickerInput__clear-date--hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.SingleDatePickerInput__calendar-icon {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}

.SingleDatePickerInput__calendar-icon svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}
