@import '~assets/sass/variables';

.kata {
  &-light-table {
    border-collapse: separate;
    border-spacing: 0;

    width: 100%;
    border: none;
    &--compact {
      width: 35%;
    }
    &--striped {
      tbody {
        tr {
          background: $gray-10;
        }
        tr:nth-child(2n) {
          background: #fff;
        }
      }
    }
    thead {
      tr {
        margin: none;
        padding: none;
        border: none;

        th {
          font-size: 12px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: 0.2px;
          color: $gray-50;
          padding-bottom: 8px;
        }
      }
    }
    tbody {
      tr {
        border-radius: 2px;
        td {
          vertical-align: middle;
          border: solid 1px $gray-30;
          padding: 4px 8px;
          font-size: 13px;
          font-weight: 500;
          color: $gray-70;

          border-left: none;
          border-right: none;
          &:nth-child(2n) {
            border-left: solid 1px $gray-30;
            border-right: solid 1px $gray-30;
          }
          &:last-child {
            border-right: solid 1px $gray-30;
          }
          &:first-child {
            border-left: solid 1px $gray-30;
          }
        }
      }
      tr.--bottomless {
        td,
        th {
          border-bottom: none;
        }
      }
      tr:first-child td:first-child {
        border-top-left-radius: 2px;
      }
      tr:first-child td:last-child {
        border-top-right-radius: 2px;
      }
      tr:last-child td:first-child {
        border-bottom-left-radius: 2px;
      }
      tr:last-child td:last-child {
        border-bottom-right-radius: 2px;
      }
    }
    &--horizontal {
      tbody {
        th {
          background: $gray-10;
          border: solid 1px $gray-30;
          padding: 4px 8px;
          font-size: 13px;
          color: $gray-70;

          &:nth-child(2n) {
            border-left: solid 1px $gray-30;
            border-right: solid 1px $gray-30;
          }
          &:last-child {
            border-right: solid 1px $gray-30;
          }
          &:first-child {
            border-left: solid 1px $gray-30;
          }
        }
        tr:first-child th:first-child {
          border-top-left-radius: 2px;
        }
        tr:first-child th:last-child {
          border-top-right-radius: 2px;
        }
        tr:last-child th:first-child {
          border-bottom-left-radius: 2px;
        }
        tr:last-child th:last-child {
          border-bottom-right-radius: 2px;
        }
      }
    }
  }

  &-client-table {
    width: 100%;
    thead {
      border: solid 2px $gray-30;
      border-radius: 6px;
      background: $gray-10;
      tr {
        font-size: 14px;
        font-weight: bold;
        color: $gray-80;
        th {
          padding: 10px 24px;
          text-align: center;
        }
      }
    }
  }

  &-table {
    width: 100%;

    &--card {
      background-color: $white;
      border-radius: $border-radius-medium;
      box-shadow: $layer-100-shadow;

      th {
        border-top: none;
      }
    }

    thead {
      border-bottom: solid 2px $gray-30;
      background: $white;

      th {
        padding: 16px;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: $gray-80;

        &:first-child {
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        border-bottom: solid 1px $gray-30;

        td {
          background: $white;
          padding: 14px 16px;
          vertical-align: middle;

          color: $gray-70;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
      }

      tr:last-child td:last-child {
        border-bottom-right-radius: 8px;
      }
    }

    &--striped {
      tbody {
        tr {
          &:nth-child(odd) {
            background: $gray-10;
          }
        }
      }
    }

    &--hover {
      tbody {
        tr {
          &:hover {
            background: $gray-10;
          }
        }
      }
    }
  }
}
