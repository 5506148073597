@import '~assets/sass/variables';

$keyword: #00aaff;
$operator: #f95faa;
$property: #75de00;
$variable: #75de00;
$string: #eeba00;
$comment: #bbbbbb;
$invalid: $red;
$other: #666666;

.ace-kata {
  border: solid 1px #d7d9de !important;
  background-color: #ffffff !important;
  color: #666666 !important;
  border-radius: 4px !important;
}

.ace-kata .ace_gutter {
  background: none !important;
  color: #4d4d4c !important;
  border-right: 1px solid #d7d9de !important;
  padding: 12px 0 !important;
}
.ace-kata .ace_scroller .ace_content {
  padding: 12px 0 !important;
}
.ace-kata .ace_print-margin {
  width: 1px !important;
  background: #f6f6f6 !important;
}
.ace-kata {
  background-color: #ffffff !important;
  color: $other !important;
}
.ace-kata .ace_cursor {
  color: #aeafad !important;
}
.ace-kata .ace_marker-layer .ace_selection {
  background: #d6d6d6 !important;
}
.ace-kata.ace_multiselect .ace_selection.ace_start {
  box-shadow: 0 0 3px 0px #ffffff !important;
}
.ace-kata .ace_marker-layer .ace_step {
  background: rgb(255, 255, 0) !important;
}
.ace-kata .ace_marker-layer .ace_bracket {
  margin: -1px 0 0 -1px !important;
  border: 1px solid #d1d1d1 !important;
}
.ace-kata .ace_marker-layer .ace_active-line {
  background: none !important;
}
.ace-kata .ace_gutter-active-line {
  background: none !important;
}
.ace-kata .ace_marker-layer .ace_selected-word {
  border: 1px solid #d6d6d6 !important;
}
.ace-kata .ace_invisible {
  color: #d1d1d1 !important;
}
.ace-kata .ace_keyword,
.ace-kata .ace_meta,
.ace-kata .ace_storage,
.ace-kata .ace_storage.ace_type,
.ace-kata .ace_support.ace_type {
  color: $keyword !important;
}
.ace-kata .ace_keyword.ace_operator {
  color: $operator !important;
}
.ace-kata .ace_constant.ace_character,
.ace-kata .ace_constant.ace_language,
.ace-kata .ace_constant.ace_numeric,
.ace-kata .ace_keyword.ace_other.ace_unit,
.ace-kata .ace_support.ace_constant,
.ace-kata .ace_variable.ace_parameter {
  color: $operator !important;
}
.ace-kata .ace_constant.ace_other {
  color: $other !important;
}
.ace-kata .ace_invalid {
  color: #ffffff !important;
  background-color: $invalid !important;
}
.ace-kata .ace_invalid.ace_deprecated {
  color: #ffffff !important;
  background-color: #8959a8 !important;
}
.ace-kata .ace_fold {
  background-color: #4271ae !important;
  border-color: #4d4d4c !important;
}
.ace-kata .ace_entity.ace_name.ace_function,
.ace-kata .ace_support.ace_function,
.ace-kata .ace_variable {
  color: #4271ae !important;
}
.ace-kata .ace_support.ace_class,
.ace-kata .ace_support.ace_type {
  color: #c99e00 !important;
}
.ace-kata .ace_heading,
.ace-kata .ace_markup.ace_heading,
.ace-kata .ace_string {
  color: $string !important;
}
.ace-kata .ace_entity.ace_name.ace_tag,
.ace-kata .ace_entity.ace_other.ace_attribute-name,
.ace-kata .ace_meta.ace_tag,
.ace-kata .ace_string.ace_regexp,
.ace-kata .ace_variable {
  color: $variable !important;
}
.ace-kata .ace_comment {
  color: $comment !important;
}
.ace-kata .ace_indent-guide {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAE0lEQVQImWP4////f4bdu3f/BwAlfgctduB85QAAAABJRU5ErkJggg==)
    right repeat-y !important;
}
