@import 'variables';

.heading1 {
  font-size: $h1-font-size !important;
  font-weight: $h1-font-weight !important;
  line-height: $h1-line-height !important;
  color: $gray-80;
}

.heading2 {
  font-size: $h2-font-size !important;
  font-weight: $h2-font-weight !important;
  line-height: $h2-line-height !important;
  color: $gray-80;
}

.title {
  font-size: $title-font-size !important;
  font-weight: $title-font-weight !important;
  line-height: $title-line-height !important;
  color: $gray-80;
}

.subtitle {
  font-size: $subtitle-font-size !important;
  font-weight: $subtitle-font-weight !important;
  line-height: $subtitle-line-height !important;
  color: $gray-80;
}

.text-label {
  font-size: $label-font-size !important;
  font-weight: $label-font-weight !important;
  line-height: $label-line-height !important;
  letter-spacing: $label-letter-spacing !important;
  color: $gray-50;
}

.text-small {
  font-size: $small-font-size !important;
  font-weight: $small-font-weight !important;
  line-height: $small-line-height !important;
  color: $gray-60;
}

.body-text {
  font-size: $body-font-size !important;
  font-weight: $body-font-weight !important;
  line-height: $body-line-height !important;
  color: $gray-70;
}

.tooltip {
  font-size: $tooltip-font-size;
  color: $white;
  letter-spacing: $tooltip-letter-spacing;
  text-align: center;
}

.text-primary {
  color: $kata-blue !important;
}

.text-success {
  color: $green !important;
}

.text-warning {
  color: $yellow !important;
}

.text-danger {
  color: $red !important;
}
