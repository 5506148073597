@import 'variables';

:root {
  font-size: $root-font-size;
  line-height: $root-line-height;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  font-family: $font-family;
  background: $gray-10;
  color: $gray-70;
}

#root {
  transition: all 0.5s cubic-bezier(0.15, 1, 0.3, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.15, 1, 0.3, 1);
}

.noscroll {
  overflow: hidden;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.full-size {
  height: 100%;
  width: 100%;
}

.full-size-content {
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

.icon-middle {
  vertical-align: middle;
}

.drag-handle {
  cursor: move;
  display: inline-block;

  &::before {
    content: '......';
    display: inline-block;
    width: 10px;
    word-break: break-word;
    white-space: normal;
    letter-spacing: 2px;
    line-height: 4.5px;
    text-align: center;
    height: 18px;
  }
}

.kata-floating-action {
  position: fixed !important;
  height: 64px !important;
  width: 64px !important;
  font-size: 24px !important;
  color: $kata-blue !important;
  bottom: 64px;
  right: 20px;

  @media (min-width: $break-medium) {
    right: 40px;
  }

  @media (min-width: $break-large) {
    right: 48px;
  }
}

.kata-btn-group {
  &__item {
    border: 1px solid $gray-30;
    color: $gray-50;
    border-radius: $border-radius-medium;
    padding: 10px 40px;
    background: $gray-10;

    &:hover,
    &:focus {
      color: $kata-blue;
      box-shadow: unset !important;
    }

    &--selected,
    &--selected:hover,
    &--selected:focus {
      color: $kata-blue;
      background: $white;
      border-color: $kata-blue;
      z-index: 5 !important;
    }
  }
}

.kata-subpage {
  &__back-btn {
    font-size: 0.85rem !important;
    font-weight: 700 !important;
    line-height: 1.23rem !important;
    display: flex !important;
    margin-top: -4px;
    margin-right: 16px;
  }

  &__title {
    margin-bottom: 4px;
  }

  &__bread-separator {
    margin: 0 8px;
  }
}
