@import '~assets/sass/variables';

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $length in $space-list {
    $size: index($space-list, $length);
    .#{$abbrev}-#{$size} {
      #{$prop}: #{$length} !important;
    }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: #{$length} !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: #{$length} !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: #{$length} !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: #{$length} !important;
    }
  }
}

.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
