@import '~assets/sass/variables';

.react-tagsinput--focused {
  border-color: #484c4f !important;
  background-color: #ffffff !important;
  border: solid 1px $kata-blue !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), 0 0 0 4px $soft-kata-blue !important;
}

.react-tagsinput-tag {
  background-color: #484c4f;
  border: 1px solid #484c4f;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-align: center;
  padding: 2px;
  margin-bottom: 4px;
  margin-right: 4px;
}

.react-tagsinput-input {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  width: 125px;
  padding: 0 2px;
  margin-top: 0;
  margin-bottom: 4px;
  margin-right: 4px;
}

.react-tagsinput-sm {
  padding: 3px 0 0 3px;
}

.react-tagsinput-sm .react-tagsinput-input {
  margin-top: 0;
  padding: 3px;
}

.react-tagsinput-sm .react-tagsinput-tag {
  padding: 0 3px;
}
