.kata-splash {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 32px;
  background-color: #f6f7f8;
  overflow-y: auto;
  z-index: 10000;
}

.kata-splash__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.kata-splash__loading-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.05px;

  &:last-of-type {
    margin-top: 16px;
  }
}

.kata-splash__error {
  display: none;
}

.kata-splash__error-title {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.kata-splash__error-details {
  margin-bottom: 16px;
  padding: 16px;
  background-color: #e2e6e8;
  border-radius: 4px;
  font-size: 13px;
  line-height: 20px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.kata-splash__error-description {
  font-size: 13px;
  line-height: 20px;
  max-width: 600px;
  overflow-x: auto;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.kata-splash--hasError .kata-splash__error {
  display: block;
}

.kata-splash--hasError .kata-splash__loading {
  display: none;
}
